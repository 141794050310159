#components-layout-demo-custom-trigger .trigger {
  padding: 0 24px;
  font-size: 18px;
  line-height: 64px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

.txtLogo {
  margin-bottom: 0px;
  font-size: 18px;
  font-weight: 600;
  color: #fff;
  padding-left: 24px;
  position: absolute;
  bottom: 5px;
}

.logo {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.logo img {
  width: 150px;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  margin: 16px;
  background: rgba(255, 255, 255, 0.3);
}
/* .ant-layout-sider {
  background-color: #fff !important;
} */
.site-layout .ant-layout-header {
  background: #334454;
  color: #fff;
}
.ant-progress-inner {
  background-color: #ccc;
}
.hideText {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* after 3 line show ... */
  -webkit-box-orient: vertical;
  margin-bottom: 0;
}
.ant-popover {
  width: 500px;
}
